body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.parent {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  overflow: auto;
  background-color: #282c34;
}

.sss{
  margin-top: 64px;
}
.xxx{
  background: #FF416C;
  background: -webkit-linear-gradient(to right, #FF4B2B, #FF416C);
  background: linear-gradient(to right, #FF4B2B, #FF416C);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 0 0;
  color: #FFFFFF;
  position: relative;
  /*left: -50%;*/
  height: 100%;
  width: 200%;
  transform: translateX(0);
  transition: transform 0.6s ease-in-out;
}
.block {
  border: blue;
  border-width: 2px;
  width: 400px;
  /*height: 250px;*/
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -200px 0 0 -300px;
}
.blockX {
  border: blue;
  border-width: 2px;
  width: 400px;
  /*height: 250px;*/
  /*position: absolute;*/
  top: 0;
  left: 50%;
  margin: 0 0 0 -300px;
}